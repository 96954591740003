import React from 'react'
import {
  HashRouter, Route, Switch, Redirect,
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const LoginSuccess = React.lazy(() => import('./views/pages/login/LoginSuccess'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const PrivateRoute = ({ children, ...rest }) => {
  const token = localStorage.getItem('token')
  return (
    <Route
      {...rest}
      render={() => (
        token ? (
          children
        ) : (
          <Redirect
            to="/login"
          />
        ))}
    />
  )
}

const App = () => (
  <HashRouter>
    <React.Suspense fallback={loading}>
      <Switch>
        <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
        <Route exact path="/login-success" name="Login Success Page" render={(props) => <LoginSuccess {...props} />} />
        <Route exact path="/register" name="Register Page" render={(props) => <Register {...props} />} />
        <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
        <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
        <PrivateRoute path="/" name="Home">
          <TheLayout />
        </PrivateRoute>
      </Switch>
      <ToastContainer />
    </React.Suspense>
  </HashRouter>
)

export default App
